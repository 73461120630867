<template>
	<div class='pt60 flex container pb60'>
		<el-backtop :bottom="60"></el-backtop>
		<div class="fs18 bg-0 color-f w100 h40 lh40 text-center mr18 pointer back_box" @click="goBack">返回列表</div>
		<div class="bg-f5 box_r" style="margin: 0 auto;">
			<!-- <img :src="img" class="w-max h-max"> -->
			<!-- <pdf :src="img" ref="pdf"/> -->
			<!-- <iframe height="100%" width=100% :src="img" ></iframe> -->
			<pdf :src='img' v-for="i in pageNum" :key="i" :page="i" />
		</div>
	</div>
</template>

<script>
	import { getPatentsDetail } from '@/api'
	import pdf from "vue-pdf";
	export default {
		name: 'reportD',
		data() {
			return {
				img: '',
				pageNum: ''
			};
		},

		components: { pdf },

		created() {
			this.$store.commit('editRouterName', this.$route.name)
			//获取详情
			this.getPatentsDetail(this.$route.params.id)
		},

		mounted() {},

		methods: {
			async getPatentsDetail(id) {
				const res = await getPatentsDetail({ id })
				this.img = res.data.patentPdf
				this.pageNum = res.data.pdfPage
				this.$nextTick(() => {
					this.getPageNum(); //pdf分页处理
				})

				// this.title = res.data
			},
			getPageNum() {
				let loadingTask = pdf.createLoadingTask(this.img, { withCredentials: false })
				loadingTask.promise.then(pdf => {
					this.pageNum = pdf.numPages
				}).catch(err => {
					console.error('pdf加载失败', err);
				})
			},
			goBack() {
				this.$router.back()
			}
		}
	}
</script>
<style lang='scss' scoped>
	.box_r {
		width: 1220px;
	}

	.back_box {
		position: sticky;
		top: 240px;
	}
</style>
